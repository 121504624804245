import "../CSS/MainNav.css";
import React from "react";

const MainNav = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <div className="navbar-collapse show" id="navbarColor01">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://github.com/noahfishxd"
                target="_blank"
                rel="noreferrer"
                title="GitHub"
              >
                <i className="fab fa-github fa-2x"></i>
                <span className="nav-text">GitHub</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="mailto:noahfisherxd@gmail.com"
                target="_blank"
                rel="noreferrer"
                title="E-Mail"
              >
                <i className="fas fa-envelope fa-2x"></i>
                <span className="nav-text">E-Mail</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://discordapp.com/users/197444850151456768"
                target="_blank"
                rel="noreferrer"
                title="Discord"
              >
                <i className="fab fa-discord fa-2x"></i>
                <span className="nav-text">Discord</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="https://twitter.com/51Onoah"
                target="_blank"
                rel="noreferrer"
                title="Twitter"
              >
                <i className="fab fa-twitter fa-2x"></i>
                <span className="nav-text">Twitter</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Portfolio" title="Portfolio">
                <i className="fas fa-briefcase fa-2x"></i>
                <span className="nav-text">Portfolio</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNav;
