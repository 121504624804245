import "../CSS/MainContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCss3,
  faHtml5,
  faReact,
  faJs,
  faPython,
} from "@fortawesome/free-brands-svg-icons";
import { SiSvelte } from "react-icons/si";

const MainContent = () => {
  return (
    <div className="main-content">
      <div className="center-content">
        <div className="main-content-icons">
          <a className="icon-container" href="https://www.python.org/">
            <FontAwesomeIcon icon={faPython} className="icon-white" />
          </a>
          <a className="icon-container" href="https://react.dev/">
            <FontAwesomeIcon icon={faReact} className="icon-white" />
          </a>
          <a className="icon-container" href="https://svelte.dev/">
            <SiSvelte className="icon-white2" />
          </a>
          <a className="icon-container" href="https://www.javascript.com/">
            <FontAwesomeIcon icon={faJs} className="icon-white" />
          </a>
          <a
            className="icon-container"
            href="https://en.wikipedia.org/wiki/CSS"
          >
            <FontAwesomeIcon icon={faCss3} className="icon-white" />
          </a>
          <a
            className="icon-container"
            href="https://en.wikipedia.org/wiki/HTML"
          >
            <FontAwesomeIcon icon={faHtml5} className="icon-white" />
          </a>
        </div>
        <div className="center-content-words">
          <div className="main-content-h1">
            <h1>Hello World,</h1>
          </div>
          <div className="main-content-p">
            <p>
              Yo, I'm Noah – Bay Area native and full-stack dev in perpetual
              beta. Refactoring my skill set with each project.
            </p>
            <p>
              Need a website that doesn't look like it was built by a Shopify
              template? Hit me up :P
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
