import "../CSS/MainFoot.css";

const MainFoot = () => {
  return (
    <div className="main-foot">
        <p className="main-foot-words">Copyright © by noahfish.dev 2023. All rights reserved. </p>
    </div>
  )
}

export default MainFoot